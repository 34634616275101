import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Quel est le coût d’une infraction pour conduite avec les facultés affaiblies au Québec?"
            description="Nous vous expliquons les coûts associés aux conséquences d'une condamnation pour conduite avec les facultés affaiblies par l'alcool au Québec!"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Alcool au volant">
            <p>
                Être arrêté et reconnu coupable de conduite avec les facultés
                affaiblies au Québec a des répercussions bien plus importantes
                qu’on ne l’imagine.{' '}
            </p>
            <p>
                <strong>
                    Au-delà de la condamnation pour l'infraction, cela engendre
                    des coûts qui peuvent peser lourdement sur la vie
                    quotidienne, tant sur le plan financier que personnel.
                </strong>
            </p>
            <p>
                Prendre conscience des coûts liés à cette infraction permet de
                mieux comprendre les véritables impacts d’une condamnation!
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Sanctions et coûts liés à l'alcool au volant !"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Dans cet article, nous vous expliquons tous les
                                coûts pouvant découler d’une arrestation et
                                d’une condamnation pour conduite avec les
                                facultés affaiblies par l’alcool au Québec!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <h2>
                Quelles sont les conséquences de la conduite avec les facultés
                affaiblies par l'alcool et quels sont les coûts liés à chaque
                sanction ?
            </h2>
            <p>
                Presque toutes les conséquences découlant d’une arrestation et
                d’une condamnation pour conduite avec facultés affaiblies
                entraînent des coûts importants.{' '}
            </p>
            <p>
                Voici un aperçu des sanctions et des frais associés à une
                première infraction pour conduite avec les facultés affaiblies
                par l'alcool au Québec :
            </p>
            <h3>
                <strong>
                    <strong>Saisie du véhicule du conducteur</strong>
                </strong>
            </h3>
            <p>
                Lors de l’arrestation pour conduite avec facultés affaiblies par
                l’alcool, le véhicule du conducteur peut être saisi et remorqué
                à ses frais. Cette mesure s’applique si:
            </p>
            <ul>
                <li>
                    Le taux d’alcoolémie du conducteur est égal ou supérieur à
                    160 mg par 100 ml de sang
                </li>
                <li>
                    Le conducteur refuse de se{' '}
                    <Link to="https://avocatsalcoolauvolant.ca/refus-souffler-alcootest/">
                        <strong>soumettre à un alcootest</strong>
                    </Link>{' '}
                </li>
            </ul>
            <p>
                Les frais de remorquage sont d’environ 125 $, et le remisage du
                véhicule pendant 30 jours peut coûter environ 25 $ par jour,
                soit un total de 750 $. En conséquence, la facture pour la
                saisie du véhicule s’élève à environ <strong>875 $.</strong>
            </p>
            <p>
                Il est à pertinent de noter qu’il est parfois possible de
                demander une mainlevée de la saisie si le conducteur arrêté
                n’est pas le propriétaire du véhicule. Cette demande permet au
                propriétaire de récupérer son véhicule avant la fin de la
                période de 30 jours, bien que des frais administratifs
                supplémentaires puissent s’ajouter à cette procédure.
            </p>
            <h3>Installation d’un dispositif antidémarrage</h3>
            <p>
                Lorsqu'un conducteur est reconnu coupable de conduite avec
                facultés affaiblies, le tribunal peut exiger l’installation d’un
                antidémarreur éthylométrique pour au moins un an.{' '}
            </p>
            <p>
                Ce dispositif empêche le démarrage du véhicule en cas de
                détection d’alcool. Son installation coûte <strong>50 $</strong>{' '}
                plus taxes, avec une location mensuelle de <strong>61 $</strong>{' '}
                plus taxes.
            </p>
            <h3>Amende obligatoire</h3>
            <p>
                Lorsqu’une personne est reconnue coupable de conduite avec
                facultés affaiblies par l’alcool, une amende est automatiquement
                imposée. Le montant de cette sanction dépend du taux
                d’alcoolémie mesuré.{' '}
            </p>
            <p>
                La loi fixe une{' '}
                <strong>amende minimale obligatoire de 1 000 $</strong>, mais
                celle-ci augmente en fonction de la concentration d’alcool dans
                le sang :
            </p>
            <ul>
                <li>
                    <strong>80 à 119 mg/100 ml</strong> : 1 000 $ d’amende
                </li>
                <li>
                    <strong>120 à 159 mg/100 ml</strong> : 1 500 $ d’amende
                </li>
                <li>
                    <strong>160 mg/100 ml ou plus</strong> : 2 000 $ ou plus
                </li>
            </ul>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Amende liée à l'alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                Bien que ces montants constituent le minimum légal, le tribunal
                peut décider d'imposer une amende plus élevée en fonction de la
                gravité de l'infraction et des circonstances particulières
                entourant sa commission.
            </p>
            <h3>
                <strong>
                    <strong>
                        Suspension et révocation de son permis de conduire
                    </strong>
                </strong>
            </h3>
            <p>
                En plus de l’amende imposée, une personne reconnue coupable de
                conduite avec facultés affaiblies par l’alcool verra son permis
                de conduire suspendu pour une durée minimale d’un an. De plus,
                le tribunal ordonnera la révocation du permis pour une période
                allant d’un à trois ans, selon les circonstances.
            </p>
            <p>
                Bien que la suspension ou la révocation du permis de conduire
                n'entraîne pas de frais directs, le conducteur devra payer s'il
                souhaite obtenir un permis restreint pendant la période de
                sanction. De plus, des frais seront requis pour récupérer son
                permis de conduire à la fin de cette période!
            </p>
            <h4>Obtention d’un permis restreint :</h4>
            <p>
                Durant la période de sanction, il est possible de demander un
                permis restreint permettant de conduire uniquement un véhicule
                équipé d’un antidémarreur éthylométrique.{' '}
            </p>
            <p>
                Pour être admissible à ce permis restreint, certaines conditions
                doivent être remplies. Par exemple, le conducteur ne doit pas
                avoir d’autres sanctions en cours, ni de problèmes de santé
                affectant sa capacité à conduire. De plus, ce permis n’est pas
                accessible aux détenteurs d’un permis d’apprenti conducteur ou
                d’un permis moto (classe 6).
            </p>
            <h4>Coûts liés au permis restreint et à l’antidémarreur:</h4>
            <p>
                L’obtention et l’utilisation d’un permis restreint entraînent
                plusieurs coûts, tels que :
            </p>
            <ul>
                <li>
                    Installation de l’antidémarreur éthylométrique :{' '}
                    <strong>50 $</strong> plus taxes
                </li>
                <li>
                    Location mensuelle de l’antidémarreur :{' '}
                    <strong>61 $</strong> plus taxes
                </li>
                <li>
                    Contribution d’assurance pour un permis restreint (2025) :{' '}
                    <strong>201,17 $</strong>
                </li>
            </ul>
            <h4>
                <strong>
                    <strong>Restrictions à respecter</strong>
                </strong>{' '}
                pour conserver le permis restreint:
            </h4>
            <p>
                Après avoir obtenu un permis restreint, le conducteur doit
                respecter plusieurs conditions pour le conserver :
            </p>
            <ul>
                <li>
                    Utiliser uniquement un véhicule équipé d’un antidémarreur
                    éthylométrique.
                </li>
                <li>
                    Ne pas tenter de désactiver, modifier ou contourner
                    l’appareil.
                </li>
                <li>Suivre les directives d’utilisation de l’appareil.</li>
                <li>
                    Assumer les frais d’installation et de location mensuelle de
                    l’antidémarreur éthylométrique.
                </li>
                <li>
                    Respecter la fréquence des entretiens et des relevés de
                    données exigés.
                </li>
                <li>
                    Ne jamais conduire avec la moindre trace d’alcool dans son
                    organisme.
                </li>
            </ul>
            <h4>Sanctions en cas de non-respect des conditions:</h4>
            <p>
                Le non-respect des conditions imposées avec le permis restreint
                peut entraîner des sanctions sévères, notamment :
            </p>
            <ul>
                <li>La saisie immédiate du véhicule pour 30 à 90 jours</li>
                <li>
                    Une amende de <strong>1 500 $ à 3 000 $</strong>
                </li>
                <li>La révocation du permis restreint</li>
            </ul>
            <h4>Obtention d’un nouveau permis après la sanction:</h4>
            <p>
                Une fois la période de sanction terminée, le conducteur devra
                suivre toutes les démarches imposées par la SAAQ pour{' '}
                <Link to="https://avocatsalcoolauvolant.ca/recuperer-permis-apres-alcool-volant/">
                    <strong>récupérer un permis de conduire valide</strong>
                </Link>
                <strong>.</strong>{' '}
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Coût du permis de conduire après alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                En plus de ces exigences, le conducteur devra également payer
                une contribution d’assurance supplémentaire, dont le montant
                varie entre <strong>420 $ et 560 $,</strong> afin de retrouver
                un permis de conduire.
            </p>
            <h3>Peine d’emprisonnement</h3>
            <p>
                Lorsqu'une personne est reconnue coupable de conduite avec les
                facultés affaiblies par l'alcool, bien qu'aucune peine
                d'emprisonnement minimale ne soit prévue, le tribunal peut
                toutefois imposer une peine d'emprisonnement pouvant aller
                jusqu’à 10 ans, selon la gravité de l’infraction.
            </p>
            <h3>Casier judiciaire</h3>
            <p>
                Une condamnation pour conduite avec facultés affaiblies entraîne
                l’inscription de l’infraction au casier judiciaire du
                contrevenant. Cette mention peut avoir des conséquences sur
                l’emploi, les voyages ou d’autres aspects de la vie quotidienne.
            </p>
            <p>
                Après une période de 5 ou 10 ans, selon la gravité de
                l’infraction, il est possible de{' '}
                <Link to="https://avocatsalcoolauvolant.ca/obtenir-pardon-alcool-volant-quebec/">
                    <strong>demander un pardon</strong>
                </Link>{' '}
                afin que celle-ci n’apparaisse plus lors des vérifications du
                casier judiciaire.{' '}
            </p>
            <p>
                La demande de pardon implique certaines démarches
                administratives et des frais, dont le coût minimal est de{' '}
                <strong>50 $</strong>.
            </p>
            <h3>Programmes imposés par le tribunal et par la SAAQ</h3>
            <p>
                Lorsqu’un conducteur est reconnu coupable de conduite avec les
                facultés affaiblies par l’alcool, il peut être tenu, soit par le
                tribunal, soit par la SAAQ, de suivre des programmes
                obligatoires avant de pouvoir récupérer son permis de conduire.{' '}
            </p>
            <p>
                Ces programmes ont pour objectif d’évaluer les capacités du
                conducteur, de le sensibiliser aux dangers de l’alcool au volant
                et de réduire les risques de récidive. Toutefois, leur mise en
                œuvre implique des coûts importants pour le contrevenant.
            </p>
            <h4>
                <strong>
                    <strong>
                        L’évaluation sommaire et le programme Alcofrein:
                    </strong>
                </strong>
            </h4>
            <p>
                À la suite d’une condamnation, le tribunal peut imposer la
                participation au Programme d’évaluation et de réduction du
                risque de conduite avec les facultés affaiblies, ainsi qu’au
                programme Alcofrein.
            </p>
            <p>
                Le programme Alcofrein, d’une durée de trois heures, coûte{' '}
                <strong>150,00 $</strong> (+ taxes) et vise à sensibiliser les
                conducteurs aux dangers de l’alcool au volant tout en
                encourageant des comportements plus sécuritaires.
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Programmes imposés en cas d’alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                Dans certains cas, la SAAQ peut autoriser le conducteur à ne
                passer que l’évaluation sommaire du Programme d’évaluation et de
                réduction du risque de conduite avec les facultés affaiblies,
                dont le coût est de <strong>351,00 $</strong> (+ taxes).{' '}
            </p>
            <p>
                Réalisée par un évaluateur agréé, cette évaluation a pour but de
                vérifier si le conducteur est capable de dissocier la
                consommation d’alcool, de drogues ou de médicaments de la
                conduite sécuritaire. À l’issue du programme, une attestation de
                réussite doit être présentée à un centre de services de la SAAQ
                pour permettre la récupération du permis de conduire.
            </p>
            <h4>
                <strong>
                    <strong>L’évaluation complète:</strong>
                </strong>
            </h4>
            <p>
                Si l’évaluation sommaire révèle un risque élevé de récidive ou
                une incapacité à dissocier consommation et conduite, la SAAQ
                peut exiger une évaluation complète.{' '}
            </p>
            <p>
                Ce processus plus approfondi, dont le coût s’élève à{' '}
                <strong>830,70 $</strong> (+ taxes), s’étend sur une période de
                7 à 9 mois. Il comprend plusieurs rencontres obligatoires avec
                un professionnel, un encadrement rigoureux et un examen médical
                à remplir par un médecin, qui doit ensuite être soumis à la
                SAAQ.
            </p>
            <p>
                Bref, selon la gravité de l’infraction et les décisions de la
                SAAQ, le coût total des programmes peut dépasser{' '}
                <strong>1 300 $</strong>.
            </p>
            <h2>
                Résumé des coûts associés à une arrestation et à une
                condamnation pour conduite avec facultés affaiblies au Québec:
            </h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Catégorie</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Détail</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Coût estimé</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2">
                        <p>Saisie du véhicule</p>
                    </td>
                    <td>
                        <p>Remorquage</p>
                    </td>
                    <td>
                        <p>125 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Remisage (30 jours à 25 $/jour)</p>
                    </td>
                    <td>
                        <p>750 $</p>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2">
                        <p>Dispositif antidémarrage</p>
                    </td>
                    <td>
                        <p>Installation</p>
                    </td>
                    <td>
                        <p>50 $ + taxes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Location mensuelle (12 mois min.)</p>
                    </td>
                    <td>
                        <p>61 $/mois + taxes (732 $/an)</p>
                    </td>
                </tr>
                <tr>
                    <td rowspan="3">
                        <p>Amende obligatoire</p>
                    </td>
                    <td>
                        <p>Alcoolémie 80 à 119 mg/100 ml</p>
                    </td>
                    <td>
                        <p>1 000 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Alcoolémie 120 à 159 mg/100 ml</p>
                    </td>
                    <td>
                        <p>1 500 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Alcoolémie 160 mg/100 ml et plus</p>
                    </td>
                    <td>
                        <p>2 000 $ ou plus</p>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2">
                        <p>Permis de conduire</p>
                    </td>
                    <td>
                        <p>Contribution d’assurance pour permis restreint</p>
                    </td>
                    <td>
                        <p>201,17 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Contribution d’assurance pour récupérer un permis
                            valide
                        </p>
                    </td>
                    <td>
                        <p>420 $ à 560 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Sanctions en cas de non-respect du permis restreint
                        </p>
                    </td>
                    <td>
                        <p>Amende</p>
                    </td>
                    <td>
                        <p>1 500 $ à 3 000 $</p>
                    </td>
                </tr>
                <tr>
                    <td rowspan="3">
                        <p>Programmes imposés par le tribunal ou la SAAQ</p>
                    </td>
                    <td>
                        <p>Programme Alcofrein</p>
                    </td>
                    <td>
                        <p>150,00 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Évaluation sommaire</p>
                    </td>
                    <td>
                        <p>351,00 $ + taxes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Évaluation complète</p>
                    </td>
                    <td>
                        <p>830,70 $ + taxes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Casier judiciaire</p>
                    </td>
                    <td>
                        <p>Demande de pardon (après 5 ou 10 ans)</p>
                    </td>
                    <td>
                        <p>50 $ minimum</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Total minimum estimé</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Sans les frais d’avocat, ni d’impact sur les
                            assurances.
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>2 904,17 $ + taxes</strong>
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Il est à noter que le total minimum estimé comprend
                l’installation et la location du dispositif antidémarrage pour
                une année, l’amende minimale de 1 000 $, les frais liés au
                permis restreint et au permis de conduire, ainsi que les coûts
                du programme Alcofrein et de l’évaluation sommaire.
            </p>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Coûts associés à une condamnation pour alcool au volant !"
                    alt=""
                />
            </p>
            <h4>Remarques :</h4>
            <ul>
                <li>
                    Les montants présentés dans le tableau sont des estimations
                    et peuvent varier en fonction de plusieurs facteurs,
                    notamment les décisions du tribunal et les exigences de la
                    SAAQ.
                </li>
                <li>
                    Des frais juridiques, tels que les honoraires d’un avocat ou
                    les démarches administratives, peuvent s'ajouter au coût
                    total de cette infraction.
                </li>
                <li>
                    L’augmentation des primes d'assurance est définitivement une
                    conséquence directe d'une condamnation pour cette
                    infraction, mais elle n'est pas prise en compte dans le
                    tableau. Elle peut entraîner des coûts supplémentaires
                    importants sur plusieurs années.
                </li>
            </ul>
            <h2>
                Quel est le coût moyen d’un avocat pour une accusation de
                conduite avec facultés affaiblies par l’alcool?
            </h2>
            <p>
                Lorsqu’une personne fait face à une accusation de conduite avec
                facultés affaiblies, elle a tout intérêt à recourir aux services
                d’un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/avocat-criminel-alcool-volant/">
                    <strong>avocat spécialisé en droit criminel</strong>
                </Link>
                . Toutefois, le coût de ces services peut varier selon plusieurs
                facteurs, allant de la complexité du dossier à la réputation de
                l’avocat.
            </p>
            <p>
                <strong>
                    En règle générale, les honoraires d’un avocat pour cette
                    infraction se situent entre 1 500 $ et 10 000 $.
                </strong>
            </p>
            <p>
                Pour une première infraction sans circonstances aggravantes, les
                frais se situent souvent dans la partie basse de cette
                fourchette. En revanche, si le dossier implique des facteurs
                aggravants, comme un taux d’alcoolémie très élevé, des dommages
                matériels ou corporels, ou encore des antécédents judiciaires,
                le coût peut grimper considérablement.
            </p>
            <p>
                <strong>
                    Quelles sont les différentes méthodes de tarification des
                    avocats pour une infraction de conduite avec facultés
                    affaiblies ?
                </strong>
            </p>
            <p>
                Les avocats facturent leurs services selon deux modèles
                principaux :
            </p>
            <ul>
                <li>
                    <strong>Tarification horaire</strong> : Certains avocats
                    préfèrent facturer leurs honoraires en fonction du temps
                    passé sur l’affaire. Dans ce cas, le taux horaire peut
                    varier de 200 $ à 500 $ selon l’expérience du professionnel
                    et la région où il exerce. Ce mode de facturation peut être
                    plus coûteux si l’affaire s’étire sur une longue période.
                </li>
                <li>
                    <strong>Forfait global</strong> : D’autres avocats proposent
                    un tarif fixe qui couvre l’ensemble de la procédure. Cette
                    approche permet au client d’avoir une meilleure visibilité
                    sur le coût total dès le départ. Ce type de forfait est
                    généralement proposé pour des affaires relativement simples,
                    qui ne nécessitent pas de nombreuses audiences ni une
                    préparation approfondie.
                </li>
            </ul>
            <h3>
                <strong>
                    <strong>
                        Quels sont les principaux facteurs influençant le coût
                        d’un avocat{' '}
                    </strong>
                </strong>
                ?
            </h3>
            <p>
                Le montant des honoraires d’un avocat spécialisé en droit
                criminel peut varier considérablement selon plusieurs éléments
                déterminants :
            </p>
            <h4>
                <strong>
                    <strong>La complexité du dossier</strong>
                </strong>
            </h4>
            <p>
                Un dossier simple, impliquant une première infraction avec un
                taux d’alcoolémie légèrement supérieur à la limite légale,
                nécessitera généralement moins d’heures de travail et sera donc
                moins coûteux.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img5.childImageSharp.fluid}
                    title="Coût d’un avocat pour une infraction d’alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                En revanche, un dossier complexe, impliquant des circonstances
                aggravantes telles qu’un accident causant des blessures ou une
                récidive, exigera une défense plus approfondie, ce qui
                augmentera considérablement les honoraires.
            </p>
            <h4>
                <strong>
                    <strong>L’expérience et la réputation de l’avocat</strong>
                </strong>
            </h4>
            <p>
                Un avocat reconnu pour son expertise en droit criminel et ayant
                une forte expérience en matière de conduite avec facultés
                affaiblies demandera des honoraires plus élevés qu’un avocat
                moins expérimenté.{' '}
            </p>
            <h4>
                <strong>
                    <strong>La localisation géographique</strong>
                </strong>
            </h4>
            <p>
                Les honoraires des avocats varient en fonction de la région où
                ils exercent. Dans les grandes villes comme Montréal ou Québec,
                où la demande pour des avocats spécialisés est plus forte et où
                le coût de la vie est plus élevé, les tarifs sont généralement
                plus importants que dans les régions rurales ou les petites
                municipalités.
            </p>
            <h4>
                <strong>
                    <strong>Les frais supplémentaires</strong>
                </strong>
            </h4>
            <p>
                En plus des honoraires de l’avocat, certaines dépenses annexes
                peuvent s’ajouter :
            </p>
            <ul>
                <li>
                    <strong>Frais de dépôt en cour</strong> : Certaines
                    procédures nécessitent des frais administratifs à payer
                    auprès du tribunal.
                </li>
                <li>
                    <strong>Honoraires d’experts</strong> : Dans certains cas,
                    un toxicologue peut être engagé pour analyser les résultats
                    des tests d’alcoolémie ou un expert en reconstitution
                    d’accidents peut être consulté pour contester la version des
                    événements de la poursuite.
                </li>
                <li>
                    <strong>Frais de déplacement</strong> : Si l’avocat doit
                    parcourir de longues distances pour rencontrer son client ou
                    pour assister aux audiences devant le tribunal, ces frais
                    peuvent être facturés en supplément.
                </li>
            </ul>
            <p>
                Avant d'engager un avocat, il est recommandé, si possible, de
                planifier une consultation initiale, parfois gratuite ou à tarif
                réduit, pour examiner les options de défense et clarifier les
                frais. Demander un devis détaillé permet d'éviter les mauvaises
                surprises et de mieux comprendre les étapes du processus
                judiciaire.
            </p>
            <p>
                Bien que les honoraires puissent représenter un investissement
                important, faire appel à un avocat compétent est absolument
                recommandé pour limiter les répercussions d’une accusation! Leur
                coût varie selon la complexité du dossier, l’expérience du
                professionnel et la région où il exerce.
            </p>
            <h2>
                Vous êtes accusé pour conduite avec les facultés affaiblies?
                Consultez un avocat spécialisé en droit criminel !
            </h2>
            <p>
                Si vous avez été arrêté pour conduite avec les facultés
                affaiblies par l'alcool et que des accusations criminelles ont
                été portées contre vous, il est vivement conseillé de faire
                appel à un avocat spécialisé en droit criminel. Une condamnation
                pour cette infraction peut entraîner des coûts importants et
                avoir des répercussions graves sur votre vie professionnelle et
                personnelle.
            </p>
            <p>
                <strong>
                    Ne sous-estimez pas l'importance de recourir aux services
                    d’un avocat compétent !
                </strong>
            </p>
            <p>
                <Img
                    fluid={data.img6.childImageSharp.fluid}
                    title="Avocat pour alcool au volant afin de limiter les conséquences !"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Trouvez rapidement un avocat en droit criminel
                                grâce à notre formulaire simple et gratuit ! Une
                                fois votre demande soumise, nous vous mettons en
                                relation avec un avocat près de chez vous.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                Ce service est entièrement gratuit et sans
                                engagement, vous offrant la liberté de faire un
                                choix en toute tranquillité!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/sanctions-couts-alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/amende-alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/cout-permis-conduire-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/programmes-alcool-volant-tribunal-saaq.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/couts-condamnation-alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/cout-avocat-alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img6: file(
            relativePath: {
                eq: "cout-alcool-volant-quebec/avocat-alcool-volant-limiter-consequences.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
